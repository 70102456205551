<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Document Checklist" slot="title" link="/helpContent/StaffDocumentCheckList" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-2">
              <FormSelect label="User Type" :items="userTypeList" item-name="name" item-value="id" v-model="filtering.selectedUserTypeId" :onChange="getStaffList" rules="required"/>
          </div>
          <div class="col-sm-2">
              <FormSelect label="Select Staff" :items="staffList" item-name="Name" item-value="id" v-model="filtering.selectedStaffId" rules="required"/>
          </div>
          <div class="col-sm-2">
              <FormSelect label="Document Category" :items="documentCategoryList" item-name="DocumentCategory" item-value="id" rules="required" v-model="filtering.selectedDocumentCategoryId" />
          </div>
          <div class="col-sm-6">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
    >
    </DataTable>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { documentChecklist, getAllStaff, getAllActiveDeactiveStaff, documentCategory } from "../api";
import tableConfig from "./table";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      filtering: {
        selectedUserTypeId: null,
        selectedStaffId: null,
        selectedDocumentCategoryId: null,
      },
      userTypeList:[
          {
              'id': 'All',
              'name': 'All Users'
          },
          {
              'id': 'Active',
              'name': 'Active Users'
          },
          {
              'id': 'Deactive',
              'name': 'Deactive Users'
          }
      ],
      staffList: [],
      documentCategoryList: [],
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      listData: [],
      allStaffList: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.getAllList();
  },
  methods: {
    async getAllList() {
      //Changes by Mahima on 18th July 2023: Fixed JIRA bug, NEIN-54
      this.filtering.selectedUserTypeId= "Active";
      this.getStaffList();
      documentCategory().then((res) => {
        this.documentCategoryList = res.data;
      });
    },
    async getStaffList(ele) {
      if (ele != undefined) {
          this.filtering.selectedUserTypeId=ele;
      }
      if(this.filtering.selectedUserTypeId){
          if (this.filtering.selectedUserTypeId === "All") {
              getAllActiveDeactiveStaff().then((res)=>{
                  this.staffList=res.data
              });
          } else if (this.filtering.selectedUserTypeId === "Active") {
              getAllStaff().then((res)=>{
                  this.staffList=res.data
              });
          } else if (this.filtering.selectedUserTypeId === "Deactive") {
              await getAllActiveDeactiveStaff().then((res)=>{
                  this.allStaffList=res.data;
              });
              
              await getAllStaff().then((res)=>{
                  this.activeStaffList = res.data;
              });

              let activeselectedStaffIds = [];
              for (let j = 0; j < this.activeStaffList.length; j++) {
                  activeselectedStaffIds.push(this.activeStaffList[j].id);
              }

              this.staffList = [];
              this.allStaffList.forEach((item) => {
                  if (activeselectedStaffIds.indexOf(item.id) == -1) {
                      this.staffList.push(item);
                  }
              });
          }
          this.filtering.selectedStaffId = this.userData.user_id;
      }
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          setTimeout(() => {
            this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;
            const filters = {};
            filters.documentCatId = this.filtering.selectedDocumentCategoryId;
            filters.staffId = this.filtering.selectedStaffId;
            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pagesize: perPage,
                  },
                ],
              },
            };
            documentChecklist(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }, 50);
        }
      });
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }

  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }

  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
</style>
