export default [
  {
    title: "Document Type",
    key: "description",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Name",
    key: "DisplayName",
    sortable: true,
    minWidth: 80,
  },

  {
    title: "Uploaded On",
    key: "UploadedOn",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Notes",
    key: "Notes",
    // sortable: true,
    minWidth: 80,
  },
  
];
